

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/location-logement-appartement-resiliation-bail-avocat.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost41 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Location d’un appartement : les droits du locataire et les motifs de résiliation de bail - Soumissions Avocat"
        description="La vie en appartement est un passage par lequel la plupart des gens passent. Que vous ayez été obligé d’aller étudier à l’extérieur pour vos études, que vous ayez quitté le nid familial pour jouir d’une pleine liberté ou que vous n’ayez tout simplement pas envie de devenir propriétaire, la LIRE PLUS"
        image={LeadImage}>
        <h1>Location d’un appartement : les droits du locataire et les motifs de résiliation de bail</h1>

					
					
						<p>La vie en appartement est un passage par lequel la plupart des gens passent. Que vous ayez été obligé d’aller étudier à l’extérieur pour vos études, que vous ayez quitté le nid familial pour jouir d’une pleine liberté ou que vous n’ayez tout simplement pas envie de devenir propriétaire, la location est une aventure qui n’est pas toujours rose. En effet, comme vous demeurez dans la propriété d’une autre personne (votre locateur), vous devez entretenir une saine relation avec celui-ci pour éviter les problèmes.</p>
<p><StaticImage alt="location-logement-appartement-resiliation-bail-avocat" src="../images/location-logement-appartement-resiliation-bail-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Cette relation en est une tout autant légale que personnelle. Elle vient donc avec des droits et des obligations de part et d’autre. Lorsque celle-ci s’envenime et que les obligations ne sont plus respectées, il y a parfois un motif pour faire résilier le bail. Si vous croyez être dans une telle situation, voyez ce qu’un avocat peut faire pour vous aider!</p>
<h2>Quel genre d’enquête en propriétaire peut-il faire sur vous avant de signer le bail?</h2>
<p>Si vous avez déjà loué un logement ou été locateur d’un appartement, vous savez que généralement, un propriétaire effectue une certaine « enquête » sur le potentiel locataire avant de lui faire signer le bail. Cependant, à l’ère de la technologie de communication de plus en plus envahissante, vous vous demandez surement jusqu’où votre nouveau propriétaire peut aller en termes d’informations exigées.</p>
<p><strong>L’enquête de crédit. </strong>Pour assurer le paiement du loyer à temps et complet, la loi permet aux locateurs d’effectuer des vérifications de crédit de leurs futurs locataires afin de vérifier leur historique. Une telle vérification se fait avec le consentement su locataire. Ce dernier n’a qu’à fournir son nom, sa date de naissance ainsi que son adresse actuelle.</p>
<p><strong>Renseignements sur l’identité. </strong>Les locateurs sont également en droit d’exiger que vous leur présentiez une pièce d’identité afin de confirmer que vous êtes bien la personne que vous prétendez être. Que vous fournissiez une carte d’assurance, un permis de conduire ou votre passeport, le locateur ne peut pas en faire de photocopie ou encore collecter l’information y apparaissant.</p>
<p><strong>Documents de référence. </strong> Bien que cela soit peu fréquent, les locateurs sont en droit de demander des références d’anciens propriétaires sur l’attitude du locataire, ses habitudes de paiement, etc.</p>
<p><strong>Les informations qu’un locateur ne peut exiger. </strong>Le propriétaire du logement que vous convoitez ne peut en aucun cas exiger que vous présentiez votre passeport, votre permis de conduire ou même votre carte d’assurance maladie. Ce sont là des documents officiels qui ne peuvent être exigés que par les institutions qui les émettent.</p>
<h2>Les droits et obligations du locataire</h2>
<p>En tant que locataire d’un logement appartenant à une autre personne vous, avez des obligations à respecter afin de respecter la loi ainsi que les termes de votre bail. En revanche, le respect des obligations de votre bail s’accompagne également de droits fondamentaux protégés par la loi. Voici donc les principaux engagements qui viennent avec votre situation :</p>
<p><StaticImage alt="droits-obligations-locataire-locateur-resiliation-bail-paiement-loyer" src="../images/droits-obligations-locataire-locateur-resiliation-bail-paiement-loyer.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Obligations : </strong></p>
<ul>
<li><strong>Paiement du loyer : </strong>Que ce soit le premier du mois ou à la date convenue entre les parties, le loyer est l’élément permettant le maintien dans les lieux. Dès le lendemain du retard du paiement, le locateur peut calculer les intérêts dus et possède un droit de recours. Lorsque le délai est tardif depuis 3 semaines, un recours en résiliation de bail peut être entamé.</li>
<li><strong>Entretien des lieux : </strong>Le locataire s’engage à maintenir le logement loué dans un état salubre et adéquat. Comme celui-ci quittera éventuellement le logement, il doit maintenir celui-ci dans un état adéquat.</li>
<li><strong>Réparations urgentes : </strong>Lorsqu’un bris survient dans un logement, le locataire a l’obligation de faire effectuer les réparations urgentes afin de préserver l’intégrité du logement. Les réparations urgentes sont celles qui ne peuvent attendre l’intervention du locateur en temps utile.</li>
<li><strong>Permettre l’accès :</strong> Le propriétaire a le droit d’accéder au logement pour y faire des travaux ou pour le faire visiter lorsque le locataire a manifesté son intention de quitter le logement au terme du bail.</li>
<li><strong>Respecter la destination : </strong>En termes de location immobilière, la « destination » fait référence à l’utilisation qui est habituellement faite de l’appartement. Par exemple, un immeuble à destination résidentielle ne devrait pas avoir des logements utilisés à des fins commerciales.</li>
</ul>
<p><strong> </strong><strong>Droits : </strong></p>
<ul>
<li><strong>Maintien dans les lieux :</strong> Lorsqu’un locataire se conforme à toutes ses obligations et que le propriétaire continue de louer le logement, celui-ci a droit au maintien des les lieux, c’est-à-dire, de renouveler son bail aux mêmes conditions (avec l’augmentation raisonnable prévue par la loi).</li>
<li><strong>Environnement sécuritaire/sain : </strong>Un locataire a droit à un logement sécuritaire et dépourvu d’éléments pouvant nuire à sa santé. Le prix du loyer n’a d’ailleurs aucun impact sur ce droit; il subsiste, quel que soit le type d’habitation.</li>
<li><strong>Sous-location :</strong> Le titulaire d’un bail de location est en droit de sous-louer son logement à une tierce personne. Toutefois, cette démarche doit se faire avec l’approbation du propriétaire de l’immeuble qui, de son côté, devra avoir de très bonnes raisons de refuser le candidat proposé.</li>
<li><strong>Jouissance paisible : </strong>Cet élément de la location consiste au fait du locateur de ne pas intervenir constamment dans la vie du locataire dans son logement. Bien que l’immeuble lui appartienne, un locateur n’est pas censé débarquer au logement de son locataire fréquemment et à l’improviste; son droit de visite se limite aux situations prévues par la loi.</li>
</ul>
<h2>Les droits et obligations du propriétaire</h2>
<p>Comme le bail résidentiel est un contrat, il implique une relation légale personnelle entre deux individus. Nous venons de voir l’état des choses du côté du locataire, mais la loi étant la voix de la justice, elle prévoit également des dispositions en faveur du propriétaire locateur.</p>
<p><StaticImage alt="droits-obligations-proprietaire-entretien-jouissance-paisible-resiliation-avocat" src="../images/droits-obligations-proprietaire-entretien-jouissance-paisible-resiliation-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Droits :</strong></p>
<ul>
<li><strong>Entretien des lieux :</strong> Comme le propriétaire doit respecter l’intimité du locataire, celui-ci est en droit d’exiger ce dernier qu’il maintienne l’appartement en bon état et qu’il prévienne toute dégradation inopportune. <strong>
</strong></li>
<li><strong>Réception du loyer :</strong> La prestation exigée par le propriétaire est également celle à laquelle il a droit. Vous devez donc, en tant que locataire, payer le loyer à temps. Comme quoi chaque obligation a un droit pour corolaire!</li>
</ul>
<p><strong>Obligations :</strong></p>
<ul>
<li><strong>Délivrance du logement :</strong> Ce terme réfère à l’obligation du propriétaire de fournir le logement convenu à la date prévue, mais également en état propre et salubre. Lorsque ce ne sera pas le cas, le locataire sera en droit de résilier le bail.</li>
<li><strong>Travaux nécessaires (ou convenus) : </strong>Lorsqu’un logement est en mauvais état avant de signer le bail, un locateur doit s’engager à effectuer les travaux nécessaires à l’intégrité de l’immeuble ainsi que ceux qui sont convenus dans le bail.</li>
<li><strong>Respecter la destination :</strong> Tout comme un locataire d’un immeuble résidentiel ne pourrait pas gérer un magasin dans un tel immeuble, un propriétaire ne pourrait implanter une opération commerciale qui perturberait la tranquillité des habitants ou encore changer la destination du logement en cours de location.</li>
</ul>
<h2>Quels sont les motifs de résiliation de bail prévus par la loi?</h2>
<p>La résiliation, c’est la solution de dernier recours pour mettre fin à une situation qui ne peut plus continuer ainsi. Quand la relation entre vous et votre locateur devient toxique, il est temps d’y mettre un terme. Mais est-ce toujours aussi simple? Loin de là! Vous devez respecter les critères prévus par la loi pour mettre fin à un bail de location! Voici donc les critères pour le locataire et le propriétaire!</p>
<p><strong>Résiliation par le locataire (avec recours): </strong>Le droit à la résiliation unilatérale du bail s’ouvrira, pour le locataire, lorsque ces situations surviendront. Ce droit devra toutefois être confirmé par la Régie du logement à l’issue d’un recours.</p>
<ul>
<li>Un locateur trouble la jouissance paisible de ses locataires. (Ex : visites fréquentes et impromptues, communication abusive, accès non-autorisés, etc.)</li>
<li>Le locateur fait défaut à son obligation d’effectuer les travaux nécessaires ou convenus entre les parties pour maintenir les lieux sécuritaires. (Ex : propriétaire refuse de réparer une tuyauterie qui fuit)</li>
<li>Le locateur laisse son immeuble se dégrader et devenir insalubre. (Ex : présence de moisissure dans les murs.)</li>
<li>Le locateur modifie la destination du logement (Ex : fait du logement un commerce).</li>
</ul>
<p><strong>Résiliation du locataire (sans recours): </strong>La loi prévoit que certaines situations justifieront la résiliation du bail sans qu’un recours à la régie du logement soit nécessaire, notamment lorsque;</p>
<ul>
<li>Le locataire devient éligible à un appartement à loyer modique.</li>
<li>Le locataire devient incapable d’habiter son appartement en raison d’un handicap. </li>
<li>Le locataire déménage dans un Centre de soins hospitaliers de longue durée.</li>
<li>La sécurité d’un(e) locataire ou de son enfant est menacée par une situation de violence conjugale et/ou sexuelle. </li>
<li>Décès du locataire. </li>
</ul>
<p><strong>Résiliation par le propriétaire. </strong>Le propriétaire sera également en droit de demander la résiliation du bail à la régie du logement lorsque :</p>
<ul>
<li>Le locataire paie fréquemment son loyer en retard</li>
<li>Le locataire endommage le logement</li>
<li>Le locataire est en retard de plus de 3 semaines sur le paiement du loyer.</li>
<li>Le locataire perturbe le droit des autres locataires à la jouissance paisible de leur appartement.</li>
</ul>
<h2>Laissez Soumissions Avocat vous trouvez un expert pour votre recours à la régie du logement!</h2>
<p><StaticImage alt="motifs-resiliation-bail-quebec-proprietaire-violation-droits" src="../images/motifs-resiliation-bail-quebec-proprietaire-violation-droits.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Que vous soyez la partie locataire ou locateur de la dispute présentée devant la régie du logement, vous avez des droits à faire valoir et respecter. Un bail étant un contrat à deux sens, il est tout à fait normal de vouloir faire respecter les termes de l’entente. Toutefois, les recours à la régie du logement étant ce qu’ils sont, vous devriez faire affaire avec un avocat qui s’y connait dans ce type de recours.</p>
<p><strong>Soumissions peut vous aider à trouver un tel expert en vous mettant en contact avec les meilleurs avocats de la province en matière de dispute immobilière et de louage.</strong></p>
<p><strong>La marche à suivre est simple, vous n’avez qu’à remplir le formulaire pour être mis en contact avec des avocats de votre région! Pourquoi attendre, nos services sont gratuits!</strong></p>
    </SeoPage>
)
export default BlogPost41
  